<template>
  <div class="bg-white shadow p-3 rounded">
    <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
      Platform Competencies
    </p>
    <div class="flex flex-row items-end justify-between">
      <div>
        <InputField
          placeholder="Search by name"
          label="Filter by"
          :onChange="setKeyword"
          leftIcon
          class="mb-2 w-inputField"
          data-testid="name-field"
        />
      </div>
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} Competencies
    </p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded">
        <div class="flex flex-row items-center focus:outline-none w-75 ml-1">
          <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
        </div>
        <div class="flex flex-grow w-1/4 mr-1">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('competency', 1)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">COMPETENCY NAME</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/5 -ml-1">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('subCompetency', 2)"
            data-testid="header-2"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">SUB-COMPETENCY</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/5 ml-auto items-center -ml-1">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('assessment', 3)"
            data-testid="header-2"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">ASSESSMENT</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/10 justify-left items-center -ml-1.5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('status', 4)"
            data-testid="header-2"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">STATUS</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[4] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div
          class="flex flex-grow max-w-1/5 justify-center items-center mr-4.5"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">ACTIONS</p>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 560px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="mappedList"
          :data-component="itemComponent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import debounce from "lodash/debounce";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import { PlaceArrivedFromEnum } from '../../../Products/store/index';

export default {
  name: "CompetenciesTable",
  components: { VirtualList },
  data: () => ({
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1, 1],
    mappedList: [],
  }),
  computed: {
    ...mapState({
      competencies: (state) => state.companies.competencies,
    }),
  },
  watch: {
    keyword() {
      this.mappedList = [];
      this.getCompetencies(this.keyword);
    },
  },
  methods: {
    ...mapActions({
      getCoreCompetencies: "companies/getCoreCompetencies",
      setArrivedToProductsEditFrom: "products/setArrivedToProductsEditFrom"
    }),
    async getCompetencies(keyword = "") {
      await this.getCoreCompetencies({
        pageNumber: 1,
        pageSize: 100000,
        keyword,
      });
      this.mappedList = this.competencies.filter((item) => item.survey);
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case "number":
          this.mappedList.reverse();
          break;
        case "competency":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * a.name.localeCompare(b.name)
          );
          break;
        case "subCompetency":
          const getSubcompetenciesString = (item) =>
            item.subCompetencies.map((x) => x.name).join(", ");
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              getSubcompetenciesString(a).localeCompare(
                getSubcompetenciesString(b)
              )
          );
          break;
        case "assessment":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] * a.survey.localeCompare(b.survey)
          );
          break;
        case "status":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * (a.active - b.active)
          );
          break;
        default:
          break;
      }
    },
    setKeyword: debounce(function(value) {
      this.keyword = value;
    }, 500),
  },
  mounted() {
    this.setArrivedToProductsEditFrom(PlaceArrivedFromEnum.GLOBAL_COMPETENCIES);
    this.getCompetencies();
  },
};
</script>
